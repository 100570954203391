import React, { useState, ChangeEvent, DragEvent } from 'react';
import logo from "../../asset/EssilorLuxottica.svg";
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Upload.css';
import { BASE_API } from '../TableAPI/Constant';

const Upload = () => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [url, setUrl] = useState<string[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [inputType, setInputType] = useState<string>('file');
    const [password, setPassword] = useState<string>('');
    const [isPasswordEnabled, setIsPasswordEnabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const inputValue: string = event.target.value;
        const urlsArray: string[] = inputValue.split('\n');
        setUrl(urlsArray);
        setIsPasswordEnabled(true);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const uploadedFiles = event.target.files;
        if (uploadedFiles) {
            setFiles((prevFiles) => [...prevFiles, ...Array.from(uploadedFiles)]);
        }
        setIsPasswordEnabled(false);
    };

    const handleButtonClick = async (type: string): Promise<void> => {
        setInputType(type);
        setUrl([]);
        setFiles([]);
        setPassword('');
        setIsPasswordEnabled(type === 'url');
        setErrorMessage('');
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        if (droppedFiles) {
            setFiles((prevFiles) => [...prevFiles, ...Array.from(droppedFiles)]);
        }
        setIsPasswordEnabled(false);
    };

    const handleRemoveFile = (index: number): void => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleApply = async () => {
        setIsLoading(true);
        setErrorMessage('');

        const mainProjectId = sessionStorage.getItem('mainProjectId') || '';
        const formData = new FormData();
        formData.append('main_project_id', mainProjectId);
        formData.append('columns_to_extract', JSON.stringify(selectedOptions));
        files.forEach((file, index) => {
            formData.append('file', file);
        });

        try {
            if (inputType === 'file' && files.length > 0) {
                const response1 = await axios.post(
                    `${BASE_API}/costSummaries/loadProjectsBackground/`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                checkUploadStatus(response1.data.uploadId, 'file');
                console.log('Response 1:', response1.data, 'main_project_id: ', mainProjectId, 'columns_to_extract', JSON.stringify(selectedOptions));
            }

            if (inputType === 'url' && isPasswordEnabled && password === 'LuxotticaMassDL#1234') {
                const urlsString = url.join(",").split(",");
                const response2 = await axios.post(`${BASE_API}/costSummaries/loadByUrlBackground/`, {
                    "urls": urlsString,
                    "main_project_id": mainProjectId,
                    "lux_pass": password,
                    "column_to_extract": selectedOptions
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                checkUploadStatus(response2.data.uploadId, 'url');
                if (response2.data.error) {
                    setErrorMessage('One or more URLs point to non-existent files. Please check and try again.');
                }

                console.log('Response 2:', response2.data, 'main_project_id: ', mainProjectId, "urls: ", urlsString);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.response);
                setErrorMessage(`Error: ${error.response?.status} - ${error.response?.statusText}`);
            } else {
                console.error('Unexpected error:', error);
                setErrorMessage('An unexpected error occurred. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const checkUploadStatus = async (uploadId: string, type: string) => {
        try {
            const response = await axios.get(`${BASE_API}/costSummaries/statusUpload/${uploadId}`);
            if (response.data.status === 'DONE') {
                console.log('Upload completato');
            } else {
                setTimeout(() => checkUploadStatus(uploadId, type), 1000);
            }
        } catch (error) {
            console.error('Error checking upload status:', error);
        }
    };

    const handleOptionToggle = (value: string): void => {
        const isSelected = selectedOptions.includes(value);
        if (isSelected) {
            setSelectedOptions(selectedOptions.filter(option => option !== value));
        } else {
            setSelectedOptions([...selectedOptions, value]);
        }
    };

    const isFormValid = url.length !== 0 || files.length > 0;

    const handleLogoClick = () => {
        window.location.href = '/search';
    };

    return (
        <>
            <header>
                <img src={logo} alt="" style={{ width: "483px", height: "100px", cursor: "pointer" }} onClick={handleLogoClick} />
                <Link className='back' to='/search' role="button" style={{ position: 'absolute', right: '1%' }}>BACK</Link>
            </header>
            <div className="wrapper">
                <section className='buttons'>
                    <button className='drags' onClick={() => handleButtonClick('file')}>DRAG & DROP</button>
                    <button className='url' onClick={() => handleButtonClick('url')}>URL</button>
                </section>

                <section className='input' onDrop={handleDrop} onDragOver={(event: DragEvent<HTMLDivElement>) => event.preventDefault()}>
                    {inputType === 'file' ? (
                        <label className="custom-file-upload">
                            <p>Drag and drop your files here</p>
                            <p>-or-</p>
                            <p>Choose Files</p>
                            <input type='file' className='drag' onChange={handleFileChange} multiple />
                        </label>
                    ) : (
                        <>
                            <textarea
                                placeholder="Insert URLs (separated by commas or new lines)"
                                value={url.join('\n')}
                                onChange={handleInputChange}
                                rows={4}
                                className="drag"
                            />
                            {isPasswordEnabled && (
                                <input
                                    type="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ display: 'block', marginTop: '10px' }}
                                />
                            )}
                        </>
                    )}
                </section>

                {files.length > 0 && (
                    <div className="file-preview">
                        <p>Selected Files:</p>
                        <ul>
                            {files.map((file, index) => (
                                <li key={index}>
                                    {file.name}
                                    <button className='remove' onClick={() => handleRemoveFile(index)}>Remove</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {errorMessage && <div className="error-message">{errorMessage}</div>}

                <div className='options' style={{ marginTop: '20px', marginBottom: '20px' }}>
                <label style={{ marginRight: '20px' }}>
                        <input
                            type="checkbox"
                            name="options"
                            value="FIN"
                            checked={selectedOptions.includes("FIN")}
                            onChange={() => handleOptionToggle("FIN")}
                        />
                        FIN
                    </label>
                    <label style={{ marginRight: '20px' }}>
                        <input
                            type="checkbox"
                            name="options"
                            value="ICE"
                            checked={selectedOptions.includes("ICE")}
                            onChange={() => handleOptionToggle("ICE")}
                        />
                        ICE
                    </label>
                    <label style={{ marginRight: '20px' }}>
                        <input
                            type="checkbox"
                            name="options"
                            value="FCE"
                            checked={selectedOptions.includes("FCE")}
                            onChange={() => handleOptionToggle("FCE")}
                        />
                        FCE 
                    </label>
                    
                </div>

                <button className="apply" disabled={!isFormValid} onClick={handleApply} style={{ position: 'absolute', right: '10%' }}>
                    APPLY
                </button>

                {isLoading && <div>Please wait, loading...</div>}
            </div>
        </>
    );
};

export default Upload;
