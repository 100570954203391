import React, { useState, createContext, useContext, useEffect } from 'react';
import { FaUser } from 'react-icons/fa';
import './Login.css';
import logo from "../../asset/EssilorLuxottica.svg";
import { BASE_API } from '../TableAPI/Constant';

// Creazione del contesto per gestire lo stato dell'utente loggato
export const UserContext = createContext<string>('');

interface Project {
  _id: string;
  customer: string;
  name: string;
}

interface ApiResponse {
  code: number;
  data: Project[];
  message: string;
}

interface SecondApiResponse {
  code: number;
  data: {
    _id: string;
    customer: string;
    name: string;
  };
  message: string;
}

const Login = () => {
  const [mainProjectId, setMainProjectId] = useState('');
  const userContext = useContext(UserContext);

  const handleLogin = async () => {
    try {
      let firstProjectId = '';
      
      // Effettua la chiamata solo se non ci sono dati disponibili
      if (!mainProjectId) {
        const response = await fetch(`${BASE_API}/mainProjects/list/`);
        const data: ApiResponse = await response.json();
        console.log(data.code);
        console.log(data.data);
        if (data.code === 0 && data.data.length > 0) {
          firstProjectId = data.data[0]._id;
          console.log('First project ID:', firstProjectId);
        }
      }
  
      // Se non ci sono dati disponibili, crea un nuovo progetto
      if (!firstProjectId) {
        console.log('Creazione di un nuovo progetto...');
        const newProject = { name: 'Capex', customer: 'Luxottica' };
        const createResponse = await fetch(`${BASE_API}/mainProjects/create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newProject)
        });
  
        const newProjectData: SecondApiResponse = await createResponse.json();
        console.log('Nuovo progetto creato:', newProjectData);
        firstProjectId = newProjectData.data._id;
      }
  
      // Imposta mainProjectId e reindirizza
      setMainProjectId(firstProjectId);
      sessionStorage.setItem('mainProjectId', firstProjectId);
      window.location.href = '/search';
    } catch (error) {
      console.error('Errore durante la gestione del login:', error);
    }
  };
  // Componente che utilizzerà il main_project_id tramite il contesto
  const LoggedInComponent = () => {
    const mainProjectId = useContext(UserContext);

    return (
      <div>
        {/* Mostra il main_project_id */}
        {mainProjectId && <p>Main Project ID: {mainProjectId}</p>}
        
        {/* Altri componenti che utilizzeranno il main_project_id */}
      </div>
    );
  };

  // Aggiungi un console.log per verificare se il componente è montato correttamente
  console.log('Componente Login montato');
  console.log('mainProjectId after login:', mainProjectId);
  return (
    <div id='loginPage'>
      
      <img className="logo" src={logo} alt="logo" style={{ width: "800px", height: "auto" }}/>
      <FaUser className='icon1' size={40} /> 
      <button className='login' onClick={handleLogin}>LOGIN</button>

      <UserContext.Provider value={mainProjectId}>

        <LoggedInComponent />
      </UserContext.Provider>
    </div>
  );
};

export default Login;
