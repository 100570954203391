import React, { useEffect, useState, useCallback } from 'react';
import { BASE_API } from './Constant';
import axios from 'axios';
import * as XLSX from 'xlsx';

interface FormDetails {
    [key: string]: string | number;
}

interface ProjectData {
    _id: string;
    datetime_extraction: string;
    extractor_version: number;
    form_details: FormDetails;
    item: Record<string, number>;
    main_project_id: string;
    project_id: number;
    version: number;
    archive_versions?: Record<string, ProjectData>;
}

export interface ApiResponse {
    datetime_extraction: string | number | Date;
    code: number;
    data: {
        current_version?: ProjectData;
        archive_versions?: Record<string, ProjectData>;
    };
    message: string;
}

interface CostSumDataMultiProps {
    projectIds: string[];
}

const CostSumDataMulti: React.FC<CostSumDataMultiProps> = ({ projectIds }) => {
    const [data, setData] = useState<{ [projectId: string]: ProjectData }>({});
    const [editableFormDetails, setEditableFormDetails] = useState<{ [projectId: string]: Partial<FormDetails> }>({});
    const [editableItems, setEditableItems] = useState<{ [projectId: string]: Record<string, number> }>({});

    const fetchData = useCallback(async () => {
        const mainProjectId = sessionStorage.getItem('mainProjectId') || '';
        try {
            const fetchedData: { [projectId: string]: ProjectData } = {};
            await Promise.all(projectIds.map(async projectId => {
                const response = await axios.get(`${BASE_API}/costSummaries/current/${mainProjectId}/data/${projectId}/`);
                const jsonData: ApiResponse = response.data;
                if (jsonData.data.current_version) {
                    fetchedData[projectId] = jsonData.data.current_version;
                }
            }));
            setData(fetchedData);
            setEditableFormDetails(
                Object.fromEntries(Object.entries(fetchedData).map(([projectId, projectData]) => [projectId, projectData.form_details]))
            );
            setEditableItems(
                Object.fromEntries(Object.entries(fetchedData).map(([projectId, projectData]) => [projectId, projectData.item]))
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [projectIds]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSave = async () => {
        try {
            await Promise.all(projectIds.map(async projectId => {
                const postData = {
                    ...data[projectId],
                    form_details: editableFormDetails[projectId],
                    item: editableItems[projectId]
                };
                await axios.post(`${BASE_API}/costSummaries/update/`, postData, {
                    headers: { 'Content-Type': 'application/json' },
                });
            }));
            console.log('Dati salvati con successo.');
            fetchData();
        } catch (error) {
            console.error('Errore durante il salvataggio dei dati:', error);
        }
    };

    const handleInputChange = (projectId: string, key: string, value: string, isItem: boolean) => {
        if (isItem) {
            updateEditableItems(projectId, key, value);
        } else {
            updateEditableFormDetails(projectId, key, value);
        }
    };

    const updateEditableItems = (projectId: string, key: string, value: string) => {
        setEditableItems(prevItems => ({
            ...prevItems,
            [projectId]: {
                ...prevItems[projectId],
                [key]: parseFloat(value),
            },
        }));
    };

    const updateEditableFormDetails = (projectId: string, key: string, value: string) => {
        setEditableFormDetails(prevDetails => ({
            ...prevDetails,
            [projectId]: {
                ...prevDetails[projectId],
                [key]: typeof prevDetails[projectId]?.[key] === 'number' ? parseFloat(value) : value,
            },
        }));
    };

    const handleExportToExcel = () => {
        const wb = XLSX.utils.book_new();
        projectIds.forEach(projectId => {
            const formDetailsSheet = XLSX.utils.json_to_sheet([editableFormDetails[projectId]]);
            XLSX.utils.book_append_sheet(wb, formDetailsSheet, `Form_Details_${projectId}`);

            const itemsSheet = XLSX.utils.json_to_sheet(Object.entries(editableItems[projectId]));
            XLSX.utils.book_append_sheet(wb, itemsSheet, `Items_${projectId}`);
        });
        XLSX.writeFile(wb, 'multi_project_data.xlsx');
    };

    return (
        <div>
            <button className="applyButton" onClick={handleExportToExcel}>
                EXPORT ALL
            </button>
            <button className="applyButton" onClick={handleSave}>
                SAVE ALL
            </button>
            {Object.keys(data).length > 0 ? (
                <>
                    <VersionTable
                        projectIds={projectIds}
                        editableFormDetails={editableFormDetails}
                        handleInputChange={handleInputChange}
                    />
                    <ItemTable
                        projectIds={projectIds}
                        editableItems={editableItems}
                        handleInputChange={handleInputChange}
                    />
                </>
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

interface VersionTableProps {
    projectIds: string[];
    editableFormDetails: { [projectId: string]: Partial<FormDetails> };
    handleInputChange: (projectId: string, key: string, value: string, isItem: boolean) => void;
}

const VersionTable: React.FC<VersionTableProps> = ({
    projectIds,
    editableFormDetails,
    handleInputChange,
}) => (
    <table style={{ width: '80%', margin: '0 auto' }}>
        <thead>
            <tr>
                <th></th>
                {projectIds.map(projectId => (
                    <th key={projectId} style={{ cursor: 'pointer' }}>
                        Project {projectId}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {Object.keys(editableFormDetails[projectIds[0]]).map((key) => (
                <tr key={key}>
                    <td>{key}</td>
                    {projectIds.map((projectId) => (
                        <td key={projectId}>
                            <input
                                type={typeof editableFormDetails[projectId]?.[key] === 'number' ? 'number' : 'text'}
                                value={editableFormDetails[projectId]?.[key] || ''}
                                onChange={(e) => handleInputChange(projectId, key, e.target.value, false)}
                            />
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
);

interface ItemTableProps {
    projectIds: string[];
    editableItems: { [projectId: string]: Record<string, number> };
    handleInputChange: (projectId: string, key: string, value: string, isItem: boolean) => void;
}

const ItemTable: React.FC<ItemTableProps> = ({
    projectIds,
    editableItems,
    handleInputChange,
}) => (
    <table style={{ width: '80%', margin: '0 auto' }}>
        <thead>
            <tr>
                <th></th>
                {projectIds.map(projectId => (
                    <th key={projectId} style={{ cursor: 'pointer' }}>
                        Project {projectId}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {Object.keys(editableItems[projectIds[0]]).map((key) => (
                <tr key={key}>
                    <td>{key}</td>
                    {projectIds.map((projectId) => (
                        <td key={projectId}>
                            <input
                                type="number"
                                value={editableItems[projectId]?.[key] || ''}
                                onChange={(e) => handleInputChange(projectId, key, e.target.value, true)}
                            />
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
);

export default CostSumDataMulti;
