import React, { useEffect, useState, useCallback } from 'react';
import { BASE_API } from './Constant';
import axios from 'axios';
import * as XLSX from 'xlsx';

interface FormDetails {
    [key: string]: string | number;
}

interface ProjectData {
    _id: string;
    datetime_extraction: string;
    extractor_version: number;
    form_details: FormDetails;
    item: Record<string, number>;
    main_project_id: string;
    project_id: number;
    version: number;
    archive_versions?: Record<string, ProjectData>;
}

export interface ApiResponse {
    datetime_extraction: string | number | Date;
    code: number;
    data: {
        current_version?: ProjectData;
        archive_versions?: Record<string, ProjectData>;
    };
    message: string;
}

interface CostSumDataProps {
    projectId: string;
}

const CostSumData: React.FC<CostSumDataProps> = ({ projectId }) => {
    const [data, setData] = useState<ApiResponse | null>(null);
    const [editableFormDetails, setEditableFormDetails] = useState<{ [version: number]: Partial<FormDetails> }>({});
    const [editableItems, setEditableItems] = useState<{ [version: number]: Record<string, number> }>({});
    const [newFormDetail, setNewFormDetail] = useState<Partial<FormDetails> | null>(null);
    const [newDetails, setNewDetails] = useState<{ name: string; value: string }[]>([]);
    const [newItem, setNewItem] = useState<Partial<Record<string, number>> | null>(null);
    const [selectedVersions, setSelectedVersions] = useState<number[]>([]);
    const [isVersionSelected, setIsVersionSelected] = useState(false);

    const fetchData = useCallback(async () => {
        try {
            const mainProjectId = sessionStorage.getItem('mainProjectId') || '';
            const response = await axios.get(`${BASE_API}/costSummaries/current/${mainProjectId}/data/${projectId}/`);
            const jsonData: ApiResponse = response.data;
            setData(jsonData);

            if (jsonData.data.current_version) {
                const formDetails = buildFormDetails(jsonData);
                const items = buildItems(jsonData);

                setEditableFormDetails(formDetails);
                setEditableItems(items);
                setSelectedVersions(getSelectedVersions(jsonData));
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [projectId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const buildFormDetails = (jsonData: ApiResponse) => {
        return Object.values(jsonData.data.archive_versions || {}).reduce(
            (acc: { [version: number]: Partial<FormDetails> }, version: ProjectData) => {
                acc[version.version] = version.form_details;
                return acc;
            },
            { [jsonData.data.current_version!.version]: jsonData.data.current_version!.form_details }
        );
    };

    const buildItems = (jsonData: ApiResponse) => {
        return Object.values(jsonData.data.archive_versions || {}).reduce(
            (acc: { [version: number]: Record<string, number> }, version: ProjectData) => {
                acc[version.version] = version.item;
                return acc;
            },
            { [jsonData.data.current_version!.version]: jsonData.data.current_version!.item }
        );
    };

    const getSelectedVersions = (jsonData: ApiResponse) => {
        return [
            jsonData.data.current_version!.version,
            ...Object.values(jsonData.data.archive_versions || {}).map(v => v.version)
        ];
    };

    const handleSave = async () => {
        if (data?.data.current_version) {
            const postData = buildPostData(data.data.current_version);
            try {
                const response = await axios.post(`${BASE_API}/costSummaries/update/`, postData, {
                    headers: { 'Content-Type': 'application/json' },
                });
                console.log('Dati salvati:', response.data);
                resetNewData();
                await fetchData();
            } catch (error) {
                console.error('Errore durante il salvataggio dei dati:', error);
            }
        }
    };

    const buildPostData = (currentVersion: ProjectData) => {
        const itemsToSave = newItem ? { ...editableItems[currentVersion.version], ...newItem } : editableItems[currentVersion.version];
        const formDetailsToSave = newFormDetail ? { ...editableFormDetails[currentVersion.version], ...newFormDetail } : editableFormDetails[currentVersion.version];

        const newDetailsToSave = newDetails.reduce((acc, detail) => {
            (acc as any)[detail.name] = detail.value;
            return acc;
        }, {} as Partial<FormDetails>);

        return {
            ...currentVersion,
            form_details: { ...formDetailsToSave, ...newDetailsToSave } as FormDetails,
            item: itemsToSave,
        };
    };

    const resetNewData = () => {
        setNewFormDetail(null);
        setNewItem(null);
        setNewDetails([]);
    };

    const handleInputChange = (version: number, key: string, value: string, isItem: boolean) => {
        if (isItem) {
            updateEditableItems(version, key, value);
        } else {
            updateEditableFormDetails(version, key, value);
        }
    };

    const updateEditableItems = (version: number, key: string, value: string) => {
        setEditableItems((prevItems) => ({
            ...prevItems,
            [version]: {
                ...prevItems[version],
                [key]: parseFloat(value),
            },
        }));
    };

    const updateEditableFormDetails = (version: number, key: string, value: string) => {
        setEditableFormDetails((prevDetails) => ({
            ...prevDetails,
            [version]: {
                ...prevDetails[version],
                [key]: typeof prevDetails[version]?.[key] === 'number' ? parseFloat(value) : value,
            },
        }));
    };

    const handleNewDetailChange = (index: number, key: keyof { name: string; value: string }, value: string) => {
        setNewDetails((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = { ...newDetails[index], [key]: value };
            return newDetails;
        });
    };

    const handleNewFormDetailChange = (key: keyof FormDetails, value: string) => {
        setNewFormDetail((prevDetail) => ({
            ...prevDetail,
            [key]: typeof prevDetail?.[key] === 'number' ? parseFloat(value) : value,
        }));
    };

    const handleNewItemChange = (key: string, value: string) => {
        setNewItem((prevItem) => ({
            ...prevItem,
            [key]: parseFloat(value),
        }));
    };

    const handleExportToExcel = () => {
        if (!data || !data.data.current_version) return;

        const wb = XLSX.utils.book_new();
        const allFormDetails = buildAllFormDetails(data);
        const formDetailsSheets = createSheetsFromDetails(allFormDetails);
        appendSheetsToWorkbook(wb, formDetailsSheets, 'Form_Details_Version_');

        const allItems = buildAllItems(data);
        const itemsSheet = XLSX.utils.json_to_sheet(allItems);
        XLSX.utils.book_append_sheet(wb, itemsSheet, 'Items');

        XLSX.writeFile(wb, 'cost_summary_data.xlsx');
    };

    const handleExportAllToExcel = () => {
        if (!data || !data.data.current_version) return;

        const wb = XLSX.utils.book_new();
        const allData = buildAllData(data);

        allData.forEach((versionData, index) => {
            const formDetailsSheet = XLSX.utils.json_to_sheet([versionData.form_details]);
            XLSX.utils.book_append_sheet(wb, formDetailsSheet, `Form_Details_Version_${index + 1}`);

            const itemsSheet = XLSX.utils.json_to_sheet(Object.entries(versionData.item));
            XLSX.utils.book_append_sheet(wb, itemsSheet, `Items_Version_${index + 1}`);
        });

        XLSX.writeFile(wb, 'all_cost_summary_data.xlsx');
    };

    const buildAllFormDetails = (data: ApiResponse) => {
        return [
            data.data.current_version!.form_details,
            ...(data.data.archive_versions ? Object.values(data.data.archive_versions).map((version) => version.form_details) : []),
        ].filter(Boolean) as FormDetails[];
    };

    const buildAllItems = (data: ApiResponse) => {
        return [
            data.data.current_version!.item,
            ...(data.data.archive_versions ? Object.values(data.data.archive_versions).map((version) => version.item) : []),
        ];
    };

    const buildAllData = (data: ApiResponse) => {
        return [
            data.data.current_version!,
            ...(data.data.archive_versions ? Object.values(data.data.archive_versions) : []),
        ].filter(Boolean) as ProjectData[];
    };

    const createSheetsFromDetails = (details: FormDetails[]) => {
        return details.map((formDetails, index) => XLSX.utils.json_to_sheet([formDetails]));
    };

    const appendSheetsToWorkbook = (wb: XLSX.WorkBook, sheets: XLSX.WorkSheet[], sheetNamePrefix: string) => {
        sheets.forEach((sheet, index) => XLSX.utils.book_append_sheet(wb, sheet, `${sheetNamePrefix}${index + 1}`));
    };

    const handleVersionClick = (version: ProjectData) => {
        if (isVersionSelected) {
            setSelectedVersions(
                [data!.data.current_version!.version, ...Object.values(data!.data.archive_versions!).map(v => v.version)]
            );
        } else {
            setSelectedVersions([version.version]);
        }
        setIsVersionSelected(!isVersionSelected);
    };

    const handleAddDetail = () => {
        setNewDetails([...newDetails, { name: "", value: "" }]);
    };

    if (!data) return <div>Loading...</div>;

    const versions = [
        data.data.current_version!,
        ...Object.values(data.data.archive_versions || {}),
    ].sort((a, b) => (a && b ? b.version - a.version : 0));

    const displayedVersions = versions.filter((version) => selectedVersions.includes(version.version));

    return (
        <div>
            <button className="applyButton" onClick={handleExportToExcel}>
                EXPORT
            </button>
            <button className="applyButton" onClick={handleExportAllToExcel}>
                EXPORT ALL
            </button>
            <button className="applyButton" onClick={handleSave}>
                SAVE
            </button>

            <VersionTable
                displayedVersions={displayedVersions}
                editableFormDetails={editableFormDetails}
                handleInputChange={handleInputChange}
                handleVersionClick={handleVersionClick}
                newFormDetail={newFormDetail}
                newDetails={newDetails}
                handleNewFormDetailChange={handleNewFormDetailChange}
                handleNewDetailChange={handleNewDetailChange}
                handleAddDetail={handleAddDetail}
            />

            <ItemTable
                displayedVersions={displayedVersions}
                editableItems={editableItems}
                handleInputChange={handleInputChange}
                handleVersionClick={handleVersionClick}
            />
        </div>
    );
};

interface VersionTableProps {
    displayedVersions: ProjectData[];
    editableFormDetails: { [version: number]: Partial<FormDetails> };
    handleInputChange: (version: number, key: string, value: string, isItem: boolean) => void;
    handleVersionClick: (version: ProjectData) => void;
    newFormDetail: Partial<FormDetails> | null;
    newDetails: { name: string; value: string }[];
    handleNewFormDetailChange: (key: keyof FormDetails, value: string) => void;
    handleNewDetailChange: (index: number, key: keyof { name: string; value: string }, value: string) => void;
    handleAddDetail: () => void;
}

const VersionTable: React.FC<VersionTableProps> = ({
    displayedVersions,
    editableFormDetails,
    handleInputChange,
    handleVersionClick,
    newFormDetail,
    newDetails,
    handleNewFormDetailChange,
    handleNewDetailChange,
    handleAddDetail,
}) => (
    <table style={{ width: '80%', margin: '0 auto' }}>
        <thead>
            <tr>
                <th></th>
                {displayedVersions.map((version) => (
                    <th key={version.version} onClick={() => handleVersionClick(version)} style={{ cursor: 'pointer' }}>
                        Version {version.version}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {Object.keys(displayedVersions[0].form_details).map((key) => (
                <tr key={key}>
                    <td>{key}</td>
                    {displayedVersions.map((version) => (
                        <td key={version.version}>
                            <input
                                type={typeof editableFormDetails[version.version]?.[key] === 'number' ? 'number' : 'text'}
                                value={editableFormDetails[version.version]?.[key] || ''}
                                onChange={(e) => handleInputChange(version.version, key, e.target.value, false)}
                            />
                        </td>
                    ))}
                </tr>
            ))}
            {newFormDetail && (
                <tr>
                    {Object.keys(newFormDetail).map((key, index) => (
                        <td key={index}>
                            <input
                                type={typeof newFormDetail[key as keyof FormDetails] === 'number' ? 'number' : 'text'}
                                value={newFormDetail[key as keyof FormDetails] || ''}
                                onChange={(e) => handleNewFormDetailChange(key as keyof FormDetails, e.target.value)}
                            />
                        </td>
                    ))}
                </tr>
            )}
            {newDetails.map((detail, index) => (
                <tr key={index}>
                    <td>
                        <input
                            type="text"
                            value={detail.name}
                            onChange={(e) => handleNewDetailChange(index, "name", e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                            type="text"
                            value={detail.value}
                            onChange={(e) => handleNewDetailChange(index, "value", e.target.value)}
                        />
                    </td>
                </tr>
            ))}
            <tr>
                <td colSpan={displayedVersions.length + 1} style={{ textAlign: "center" }}>
                    <button onClick={handleAddDetail} style={{ border: "none", background: "none", color: "blue", textDecoration: "underline", cursor: "pointer" }}>
                        ADD DETAILS +
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
);

interface ItemTableProps {
    displayedVersions: ProjectData[];
    editableItems: { [version: number]: Record<string, number> };
    handleInputChange: (version: number, key: string, value: string, isItem: boolean) => void;
    handleVersionClick: (version: ProjectData) => void;
}

const ItemTable: React.FC<ItemTableProps> = ({
    displayedVersions,
    editableItems,
    handleInputChange,
    handleVersionClick,
}) => (
    <table style={{ width: '80%', margin: '0 auto' }}>
        <thead>
            <tr>
                <th></th>
                {displayedVersions.map((version) => (
                    <th key={version.version} onClick={() => handleVersionClick(version)} style={{ cursor: 'pointer' }}>
                        Version {version.version}
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {Object.keys(displayedVersions[0].item).map((key) => (
                <tr key={key}>
                    <td>{key}</td>
                    {displayedVersions.map((version) => (
                        <td key={version.version}>
                            <input
                                type="number"
                                value={editableItems[version.version]?.[key] || ''}
                                onChange={(e) => handleInputChange(version.version, key, e.target.value, true)}
                            />
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
);

export default CostSumData;
