import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './Search.css';
import logo from "../../asset/EssilorLuxottica.svg";
import searchIcon from '../../asset/search-icon.png';
import { Project } from '../TableAPI/CostSumTable';
import { BASE_API } from '../TableAPI/Constant';
import { UserContext } from '../Login/Login';
import CostSumData from '../TableAPI/CostSumData';
import CostSumDataMulti from '../TableAPI/CostSumDataMulti';

const Search = () => {
  const [showTable, setShowTable] = useState(false);
  const [dataCostSummaries, setDataCostSummaries] = useState<Project[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<Project[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const mainProjectId = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const mainProjectId = sessionStorage.getItem('mainProjectId') || '';
        const response = await axios.get(`${BASE_API}/costSummaries/current/${mainProjectId}/`);
        const responseData = response.data;
        if (responseData && responseData.data) {
          setDataCostSummaries(responseData.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [mainProjectId]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    if (value.trim() === '') {
      setSuggestions([]);
      setErrorMessage('');
      return;
    }

    const lastInput = value.split(',').pop()?.trim() || '';
    const filteredSuggestions = dataCostSummaries.filter(project =>
      (typeof project.form_details.NAME === 'string' && project.form_details.NAME.toLowerCase().includes(lastInput.toLowerCase())) ||
      project.project_id.toString().includes(lastInput)
    );
    setSuggestions(filteredSuggestions);
    if (filteredSuggestions.length === 0) {
      setErrorMessage('No suggestions found');
    } else {
      setErrorMessage('');
    }
  }

  const handleSelectSuggestion = (selectedProject: Project) => {
    const existingProjects = inputValue.split(',').map(project => project.trim()).filter(project => project !== '');
    existingProjects[existingProjects.length - 1] = selectedProject.form_details.NAME.toString();
    const newInputValue = existingProjects.join(', ') + ', ';
    setInputValue(newInputValue);
    setSuggestions([]);
  }

  const handleSearchClick = () => {
    const projectNames = inputValue.split(',').map(name => name.trim()).filter(name => name !== '');
    const selectedProjects = dataCostSummaries
      .filter(project => projectNames.includes(project.form_details.NAME.toString()))
      .map(project => project.project_id.toString());
    setSelectedProjects(selectedProjects);
    setShowTable(true);
  }

  const handleLogoClick = () => {
    window.location.href = '/search';
  };

  return (
    <div>
      <div className='top'>
      <img src={logo} alt="" style={{ width: "483px", height: "100px", cursor: "pointer" }} onClick={handleLogoClick} />
        <a className='up' href='/upload' role="button">UPLOAD</a>
      </div>
      <div className='searchbar'>
        <input
          className="search"
          placeholder="Type Project Name or Project Code"
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          style={{ paddingLeft: '10px'}}
        />
        <img 
          src={searchIcon} 
          alt="Search" 
          className="search-icon" 
          onClick={handleSearchClick} 
          style={{ cursor: 'pointer', position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', width: '24px', height: 'auto' }}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {suggestions.length > 0 && (
          <div className='suggestions'>
            {suggestions.map(project => (
              <div
                key={project._id}
                className='suggestion-item'
                onClick={() => handleSelectSuggestion(project)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onMouseEnter={(e: React.MouseEvent<HTMLElement>) => (e.target as HTMLElement).style.backgroundColor = '#f0f0f0'}
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) => (e.target as HTMLElement).style.backgroundColor = 'transparent'}
              >
                {project.form_details.NAME} ({project.project_id})
              </div>
            ))}
          </div>
        )}
      </div>

      {showTable && selectedProjects.length === 1 && (
        <CostSumData projectId={selectedProjects[0]} />
      )}

      {showTable && selectedProjects.length > 1 && (
        <CostSumDataMulti projectIds={selectedProjects} />
      )}
    </div>
  );
};

export default Search;
